import * as React from "react";
import HeroSection from "../components/hero-section";
// import IntroSection from "../components/intro-section";
// import FeatureOne from "../components/feature-one";
// import CTA1 from "../components/c-t-a1";
// import FeatureTwo from "../components/feature-two";
// import FeatureThree from "../components/feature-three";
// import PriceOne from "../components/price-one";
// import CTA28 from "../components/c-t-a28";
// import CTA19 from "../components/c-t-a19";
// import FeatureFour from "../components/feature-four";
// import CTA20 from "../components/c-t-a20";
// import FooterOne from "../components/footer-one";
import styled from "styled-components";

const HomeRoot = styled.main`
  width: 100%;
  // height: 556.938rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Home = () => {
  return (
    <HomeRoot className='HomeRoot'>
      <HeroSection />
      {/* <IntroSection />
      <FeatureOne />
      <CTA1 />
      <FeatureTwo />
      <FeatureThree />
      <PriceOne />
      <CTA28 />
      <CTA19 />
      <FeatureFour />
      <CTA20 />
      <FooterOne /> */}
    </HomeRoot>
  );
};

export default Home;
