import * as React from "react";
import { useEffect } from "react";
import { Button } from "@mui/material";
import Navbar from "./navbar";
import styled, { keyframes } from "styled-components";

const animationSlideInBottom = keyframes`
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }    
  `;
const MediumLengthHero = styled.h1`
  margin: 0;
  align-self: stretch;
  height: 12.563rem;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationSlideInBottom};
  }
`;
const LoremIpsumDolor = styled.div`
  align-self: stretch;
  height: 5.063rem;
  position: relative;
  font-size: var(--text-medium-semi-bold-size);
  line-height: 150%;
  display: inline-block;
  opacity: 0;
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationSlideInBottom};
  }
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem 0rem;
`;
const StyleprimarySmalltrueDar = styled(Button)``;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) 0rem 0rem;
  gap: 0rem 1rem;
  opacity: 0;
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationSlideInBottom};
  }
`;
const Column = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem 0rem;
  max-width: 43.75rem;
`;
const HeaderRoot = styled.header`
  align-self: stretch;
  height: 56.25rem;
  // overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url("https://corporate-compliance.netlify.app/header--30@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--heading-desktop-h1-size);
  color: var(--text-alternate);
  font-family: var(--heading-desktop-h2);
`;

const HeroSection = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <HeaderRoot className='HeroSection'>
      <Navbar />
      <Column>
        <Content>
          <MediumLengthHero data-animate-on-scroll>
            Empowering Government Agencies with Continuous Compliance Solutions
          </MediumLengthHero>
          <LoremIpsumDolor data-animate-on-scroll>
            We provide government agencies with the tools they need to
            effectively manage compliance to known frameworks. Our solutions
            streamline the process, ensuring agencies stay up-to-date and in
            line with regulations.
          </LoremIpsumDolor>
        </Content>
        <Actions data-animate-on-scroll>
          <StyleprimarySmalltrueDar
            disableElevation={true}
            color="primary"
            variant="contained"
          >
            Learn More
          </StyleprimarySmalltrueDar>
          <StyleprimarySmalltrueDar
            disableElevation={true}
            color="primary"
            variant="contained"
          >
            Sign Up
          </StyleprimarySmalltrueDar>
        </Actions>
      </Column>
    </HeaderRoot>
  );
};

export default HeroSection;
